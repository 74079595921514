<template>
  <b-card :title="fileName && clientName ? `Images de la ${fileName} ` : 'Justificatifs'">
    <b-container
      fluid
      class="p-1 bg-dark"
    >
    
      <span
        v-if="proof_files == null"
        class="text-center font-small-4"
      >
        {{ is_null }}
      </span>

      <span
        v-else-if="proof_files.length == 0"
        class="text-center font-small-4"
      >
        Chargement ...
      </span>
      <b-row v-else>
        <b-col
          v-for="proof_file in proof_files"
          :key="proof_file"
        >
          <b-img
          v-if="proof_file.caution == undefined"
            style="cursor: pointer"
            thumbnail
            fluid
            :src="proof_file"
            @click="openProofFiles(proof_file)"
          />
          <b-img
            v-else
            style="cursor: pointer"
            thumbnail
            fluid
            src="../../../assets/images/caution.png"
            @click="openProofFiles(proof_file.caution)"
          /> 
        </b-col>
      </b-row>

      
      
    </b-container>
  </b-card>
</template>

<script>
import {
  BImg,
  BContainer,
  BRow,
  BCol,
  // BCardText,
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BContainer,
    // BCardText,
    BRow,
    BCol,
  },
  props: {
    fileName: {
      type: String,
      default: '',
    },

    is_null: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    proof_files: {
      default: [],
    },
    caution_files: {
      default: [],
    },
    cautionFiles: ""
  },
  
  created(){
    console.log(this.caution_files)
    console.log(this.proof_files)
  },
  methods: {
    openProofFiles(url) {
      window.open(url)
    },

    extractCautionFiles() {
      console.log(this.proof_files)
        this.cautionFiles = this.proof_files.filter(function(file) {
            return file.endsWith('.pdf');
        });
        console.log(this.cautionFiles)
    }
  },
}
</script>
