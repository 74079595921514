<template>
  <div>
    <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/employees">Liste des Employés</b-breadcrumb-item>
      <b-breadcrumb-item active>Détails</b-breadcrumb-item>
    </b-breadcrumb>
    <!-- Alert: No item found -->
    <div
      v-if="isLoadingEmployeeDetails"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>

    <div v-else-if="error" class="text-center mt-5" style="padding-top: 30vh">
      <h1 class="text-danger">
        <feather-icon icon="XCircleIcon" size="100" />
      </h1>
      <p class="font-medium-1 text-danger font-weight-normal">
        {{ isError }}
      </p>
    </div>

    <template v-else>
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="9" lg="8" md="7">
          <b-card>
            <b-row>
              <!-- User Info: Left col -->
              <b-col
                cols="21"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :text="avatarText(employee.full_name)"
                    :src="employee.profile_image"
                    size="104px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ employee.full_name }}
                      </h4>
                      <div>
                        <span class="card-text text-info font-medium-1"
                          >MTN Momo:
                          {{
                            employee.mtn_number !== null
                              ? employee.mtn_number
                              : "Non renseigné"
                          }}</span
                        >
                      </div>
                      <div>
                        <span class="card-text text-info font-medium-1"
                          >Flooz Momo:
                          {{
                            employee.flooz_number !== null
                              ? employee.flooz_number
                              : "Non renseigné"
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="d-flex flex-wrap">
                      <div class="d-flex align-items-center mr-2">
                        <b-avatar variant="light-secondary" rounded>
                          <feather-icon icon="PhoneIcon" size="18" />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">Téléphone</h5>
                          <small class="font-small-3">{{
                            employee.phone_number
                          }}</small>
                        </div>
                      </div>

                      <div class="d-flex align-items-center mt-1">
                        <b-avatar variant="light-secondary" rounded>
                          <feather-icon icon="MapPinIcon" size="18" />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">Adresse</h5>
                          <small class="font-small-3">{{
                            employee.address
                          }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- User Stats -->
                <div class="d-flex flex-wrap mt-2">
                  <b-button variant="primary" @click="goToUpdateEmployeePage()">
                    Modifier
                  </b-button>
                  <div v-if="employee.status !== 0 && employee.status !== 2">
                    <b-button
                      v-if="employee.status === 3"
                      variant="outline-warning"
                      class="ml-1"
                      @click="applyUnSuspendedEmployeeAction"
                    >
                      Annuler la suspension
                    </b-button>
                    <b-button
                      v-else
                      v-b-modal.modal-suspend-employee
                      variant="outline-danger"
                      class="ml-1"
                    >
                      Suspendre
                    </b-button>
                  </div>
                </div>
              </b-col>

              <!-- Right Col: Table -->
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CalendarIcon" class="mr-75" />
                      <span class="font-weight-bold">Date d'inscription</span>
                    </th>
                    <td class="pb-50">
                      {{
                        moment(employee.created_at).locale("fr").format("llll")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="UserCheckIcon" class="mr-75" />
                      <span class="font-weight-bold">Validé le </span>
                    </th>
                    <td class="pb-50">
                      {{
                        employee.accepted_at != null
                          ? moment(employee.accepted_at)
                              .locale("fr")
                              .format("llll")
                          : "----"
                      }}
                    </td>
                  </tr>

                  <!-- <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Moyenne des étoiles</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ proMoyenne }}
                    </td>
                  </tr> -->
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CalendarIcon" class="mr-75" />
                      <span class="font-weight-bold">Âge</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ employee.age }} ans
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="BriefcaseIcon" class="mr-75" />
                      <span class="font-weight-bold"
                        >Diplôme/Niveau d'études</span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ employee.degree }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="HeartIcon" class="mr-75" />
                      <span class="font-weight-bold"
                        >Situation matrimoniale</span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ employee.marital_status }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="UserIcon" class="mr-75" />
                      <span class="font-weight-bold">Nationalité</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ employee.nationality }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="UserIcon" class="mr-75" />
                      <span class="font-weight-bold">IFU</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ employee.ifu }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <b-card no-body class="border-primary">
            <b-card-body>
              <h5 class="mb-0">
                Status de l'employé:
                <span v-if="employee.status === 2">
                  <b-badge
                    pill
                    variant="light-info"
                    class="text-capitalize font-small-3"
                  >
                    Occupé(e)
                  </b-badge>
                </span>
                <span v-else>
                  <b-badge
                    v-if="employee.status === 1"
                    pill
                    variant="light-success"
                    class="text-capitalize font-small-3"
                  >
                    En attente de confirmation
                  </b-badge>
                  <b-badge
                    v-else-if="employee.status === 3"
                    pill
                    variant="light-danger"
                    class="text-capitalize font-small-3"
                  >
                    Suspendu
                  </b-badge>
                  <b-badge
                    v-else-if="employee.status === 4"
                    pill
                    variant="light-info"
                    class="text-capitalize font-small-3"
                  >
                    Candidature spontané(e)
                  </b-badge>
                  <b-badge
                    v-else-if="employee.status === 0"
                    pill
                    variant="light-warning"
                    class="text-capitalize font-small-3"
                  >
                    En attente de validation
                  </b-badge>
                  <b-badge
                    v-else-if="employee.status === 5"
                    pill
                    variant="light-success"
                    class="text-capitalize font-small-3"
                  >
                    Confirmé
                  </b-badge>
                </span>
              </h5>
              <br
                v-if="
                  employee.point_focal !== null &&
                  employee.point_focal_id !== null
                "
              />
              <div
                v-if="
                  employee.point_focal !== null &&
                  employee.point_focal_id !== null
                "
              >
                <span class="font-small-2">Point Focal:</span>
                <span class="text-info">
                  {{ employee.point_focal.responsable_name }} ({{
                    employee.point_focal.town_name
                  }})</span
                >
              </div>
              <br />
              <div class="mb-0 font-small-4">
                Programme YLOMI:
                <span
                  :class="
                    employee.is_in_ylomi_program
                      ? 'text-success'
                      : 'text-warning'
                  "
                >
                  {{
                    employee.is_in_ylomi_program
                      ? "Participe"
                      : "Ne participe pas"
                  }}
                </span>
              </div>
              <br />
              <div>
                <b-button
                  v-if="
                    (getUser.role.slug === 'super-administrateur' &&
                      employee.status == 1) ||
                    (getUser.role.slug === 'superviseur' &&
                      employee.status == 1)
                  "
                  :disabled="employee.status == 5"
                  variant="success"
                  block
                  @click="applyConfirmEmployeeAction"
                >
                  Confirmer l'employé
                </b-button>
                <b-button
                  v-b-modal.modal-rejet-employee
                  v-if="
                    getUser.role.slug === 'super-administrateur' &&
                    employee.status == 1
                  "
                  :disabled="employee.status == 5"
                  variant="danger "
                  block
                >
                  Rejeter l'employé
                </b-button>
                <b-button
                  v-if="
                    (getUser.role.slug === 'rh' ||
                      getUser.role.slug === 'super-administrateur') &&
                    employee.status == 0
                  "
                  :disabled="employee.status == 1"
                  variant="success"
                  block
                  @click="applyValidateEmployeeAction"
                >
                  Valider l'employé
                </b-button>
                <b-button
                  v-if="employee.status == 4"
                  variant="info"
                  block
                  @click="applyValidateEmployeeCandidatureAction"
                >
                  Valider la candidature
                </b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- offers -->
      <b-card title="Services & Photos">
        <b-row>
          <b-col class="d-flex align-items-center justify-content-start mb-2">
            <b-button variant="primary" @click="addServiceAction()">
              <div v-if="isReLoadDataRecurringOrders">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else class="text-nowrap font-medium-1"
                >Ajouter un service</span
              >
            </b-button>
          </b-col>

          <b-col class="d-flex align-items-center justify-content-end mb-2">
            <b-dropdown
              class="text-nowrap font-medium-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Fiche de caution"
              variant="primary"
            >
              <b-dropdown-item @click="copyCautionLink()"
                >Copier lien de caution</b-dropdown-item
              >
              <b-dropdown-item @click="sendCautionLink()"
                >Envoyer lien de caution</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <b-table
          ref="refInvoiceListTable"
          :items="employee.recurring_services"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Liste vide"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #cell(checkbox)="data">
            <b-form-checkbox
              v-model="selectedServices"
              :value="data.item.id"
            ></b-form-checkbox>
          </template>

          <template #table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle" /> &nbsp;
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- Column: Invoice Status -->
          <template #cell(about)="data">
            <p>{{ data.item.pivot.about }}</p>
          </template>

          <!-- Column: Invoice Status -->
          <template #cell(years_of_experience)="data">
            <p>{{ data.item.pivot.years_of_experience }}</p>
          </template>

          <template #cell(salary_pretention)="data">
            <p>{{ data.item.pivot.salary_pretention }}</p>
          </template>

          <!-- Column: professional -->
          <template #cell(pictures)="data">
            <feather-icon
              icon="EyeIcon"
              style="cursor: pointer"
              size="30"
              class="align-middle text-body"
              @click="
                showWorksPicture(
                  data.item.name,
                  JSON.parse(data.item.pivot.pictures)
                )
              "
            />
          </template>

          <!-- Column: Actions -->
          <template v-if="employee.status != 4" #cell(actions)="data">
            <div class="text-nowrap">
              <!-- Dropdown -->
              <b-dropdown variant="link" toggle-class="p-0" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="applyUpdateServiceAction(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Modifier le service</span>
                </b-dropdown-item>
                <b-dropdown-item @click="applyDeleteServiceAction(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Supprimer le service</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="applyGetEmployeeNote(data.item)"
                  v-b-modal.modal-notes-avis
                >
                  <feather-icon icon="StarIcon" />
                  <span class="align-middle ml-50">Notes et avis</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <div class="d-flex flex-column text-md-center">
          <div class="p-2">
            <b-button
              variant="outline-success"
              :disabled="selectedServices.length == 0"
              @click="applySendEmployeeInFormationAction"
            >
              <div v-if="isMakingSoumissionLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Envoyer en formation</span>
            </b-button>
          </div>
        </div>
      </b-card>

      <!-- List des notes et avis sur les employés-->
      <b-modal
        id="modal-notes-avis"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Notes et avis"
        size="xl"
        stacking
      >
        <b-table
          ref="refInvoiceListTable"
          :items="notes"
          responsive
          :fields="columnsNotes"
          primary-key="id"
          :busy="isNotes"
          show-empty
          empty-text="Liste vide"
          class="position-relative"
        >
          <template #table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle" /> &nbsp;
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- Client -->
          <template #cell(client_note)="data">
            <span> {{ data.item.user.full_name }} </span>
          </template>
          <!-- Mois -->
          <template #cell(note_month)="data">
            <span>
              {{ data.item.month_note }}
            </span>
          </template>
          <!-- Mois -->
          <template #cell(year)="data">
            <span>
              {{ data.item.year }}
            </span>
          </template>
          <!-- Note -->
          <template #cell(note)="data">
            <span>
              {{ data.item.note }}
            </span>
          </template>
          <!-- Note -->
          <template #cell(avis)="data">
            <span>
              {{ data.item.comment }}
            </span>
          </template>
        </b-table>
      </b-modal>

      <!-- Modal suspension employee -->
      <b-modal
        id="modal-suspend-employee"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Raison de suspension"
      >
        <validation-observer #default="{}" ref="suspendReason">
          <!-- Form -->
          <b-form class="p-2" autocomplete="off">
            <b-form-group
              label="Raison de suspension de l'employé"
              label-for="suspend_reason"
            >
              <validation-provider
                #default="{ errors }"
                name="Raison de suspension de l'employé"
                rules="required"
              >
                <b-form-group
                  label="Raison de suspension de l'employé"
                  label-for="suspend_reason"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="suspend_reason"
                    v-model="suspend_reason"
                    placeholder="Laissez une raison de suspension de l'employé"
                    rows="3"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                @click="applySuspendedEmployeeAction()"
              >
                <div v-if="isSuspendEmployeeLoading">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else>Suspendre</span>
              </b-button>
              <b-button
                type="reset"
                variant="outline-secondary"
                @click="hideModalSuspendedEmployee()"
              >
                Annuler
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Reject Employee  Modal -->
      <b-modal
        id="modal-rejet-employee"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Rejeter la confirmation  de l' Employé"
      >
        <validation-observer #default="{}" ref="rejectEmployeeForm">
          <!-- Form -->
          <b-form class="p-2" autocomplete="off">
            <validation-provider
              #default="{ errors }"
              name="raison"
              rules="required"
            >
              <b-form-group
                label="Raison du rejet"
                label-for="reason"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea
                  id="reject-reason"
                  v-model="rejectEmployeePayload.reason"
                  placeholder="Laissez la raison du reject"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                :disabled="isRejectEmployeeLoading"
                variant="primary"
                class="mr-2"
                @click="applyRejectEmployeeAction()"
              >
                <div v-if="isRejectEmployeeLoading">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else>Rejeter</span>
              </b-button>

              <b-button
                variant="outline-secondary"
                @click="hideModalRejectEmployee()"
              >
                Annuler
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>

      <works-picture
        service-name=""
        :default-value="defaultValue"
        :is-null="isNull"
        :pictures="pictures"
      />

      <proof-files
        file-name=""
        :is-null="is_null"
        :proof_files="proof_files"
        :caution_files="caution_files"
      />
    </template>
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  // BFormInput,
  BButton,
  BTable,
  BForm,
  BFormTextarea,
  // BFormGroup,
  // BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BModal,
  // BFormSelect,
  BSpinner,
  BCardBody,
  // BPagination,
  // BTooltip,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum } from "@validations";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import WorksPicture from "../professionals/WorksPicture.vue";
import ProofFiles from "../professionals/ProofFiles.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BCard,
    WorksPicture,
    ProofFiles,
    BCardHeader,
    BCardBody,
    BAlert,
    BRow,
    BModal,
    BCol,
    BButton,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    BFormTextarea,
    BBreadcrumb,
    BBreadcrumbItem,
    BSpinner,
    BFormCheckbox,
  },
  data() {
    return {
      rejectEmployeePayload: {
        reason: "",
      },
      selectedServices: [],
      isMakingSoumissionLoading: false,
      isRejectEmployeeLoading: false,
      employee: null,
      isLoadingEmployeeDetails: false,
      isSuspendEmployeeLoading: false,
      required,
      suspend_reason: "",
      isNull: "Ce pro n'a fourni aucune réalisation pour son service",
      is_null: "Ce pro n'a fourni aucun justificatifspour son service",
      defaultValue: "Cliquez sur l'icon pour afficher les réalisations ici",
      alphaNum,
      serviceName: "",
      FileName: "",
      pictures: -1,
      proof_files: null,
      caution_files: null,
      cautionArray: [],
      totalWorks: "chargement...",
      proMoyenne: "chargement...",
      tableColumns: [
        {
          key: "checkbox",
          label: "Checkbox",
          sortable: true,
          class: "font-medium-1",
        },
        { key: "name", label: "Nom du service", sortable: true },
        { key: "about", label: "Compétences", sortable: true },
        { key: "years_of_experience", label: "Expérience", sortable: true },
        {
          key: "salary_pretention",
          label: "Prétention salariale",
          sortable: true,
        },

        { key: "pictures", label: "Photos", sortable: true },
        { key: "actions", label: "Actions", sortable: false },
      ],
      avatarText,
      isComments: false,
      isNotes: false,
      notes: [],
      noteEmployee: {
        comment: "",
        mont_note: "",
        note: "",
        user: {},
      },
      columnsNotes: [
        {
          key: "client_note",
          label: "Client",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "note_month",
          label: "Mois",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "year",
          label: "Année",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "note",
          label: "Notes",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "avis",
          label: "Avis",
          sortable: true,
          class: "font-medium-1",
        },
      ],
      cautionLink: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("professionals", ["getEmployeeDetails"]),
  },

  watch: {},
  created() {
    if (this.getEmployeeDetails == null) {
      this.applyGetEmployeeDetailsAction();
    } else {
      this.employee = this.getEmployeeDetails;
    }
    console.log("show", this.employee);

    if (this.employee != null) {
      const proof_fileArray = JSON.parse(this.employee.proof_files);
      if (this.employee.caution_file != null) {
        proof_fileArray.push(this.employee.caution_file);
      }

      this.applyGetS3ProofFilesAction(proof_fileArray);
    }
  },
  methods: {
    goToUpdateEmployeePage() {
      this.$router.push(`/employees/${this.$route.params.id}/update`);
    },
    ...mapActions(["getS3FileTemporaryUrlAction"]),

    ...mapActions("professionals", [
      "validateEmployeeAction",
      "validateEmployeeCandidatureAction",
      "getEmployeesDetailsAction",
      "getEmployeesAction",
      "sendEmployeeInFormationAction",
      "suspendEmployeeAction",
      "unsuspendEmployeeAction",
      "getEmployeeNoteAction",
      "rejectEmployeeAction",
      "deleteServiceEmployeeAction",
      "getCautionLinkAction",
    ]),
    applyGetCautionLink() {
      this.getCautionLinkAction()
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendCautionLink() {
      window.open(`https://wa.me/${this.employee.phone_number}?text=Bonjour Mr/Mme!*${this.employee.full_name}* Merci de cliquer sur le lien suivant afin de faire remplir ce formulaire par votre cautionnaire pour la fiche de caution:
                    \n *https://www.ylomi.net/employee/${this.employee.id}/caution/*`);
    },
    copyCautionLink() {
      this.cautionLink = `https://www.ylomi.net/employee/${this.employee.id}/caution/`;

      navigator.clipboard.writeText(this.cautionLink).then(() => {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Succès",
              icon: "CheckIcon",
              text: "Lien copié avec succès",
              variant: "success",
            },
          },
          {
            position: "top-center",
          }
        );
      });
    },

    addServiceAction() {
      this.$router.push(`/employees/${this.$route.params.id}/add/service`);
    },

    applyUpdateServiceAction(item) {
      const serviceId = item.pivot.recurring_service_id;
      this.$router.push(
        `/employees/${serviceId}/update/service?employeeId=${this.$route.params.id}`
      );
    },
    applyDeleteServiceAction(data) {
      console.log(data);
      this.$swal({
        title: "Suppression",
        text: "Êtes-vous sûr de vouloir supprimer ce service?",
        icon: "danger",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () =>
          await this.deleteServiceEmployeeAction({
            employeeId: this.$route.params.id,
            serviceId: data.pivot.recurring_service_id,
          })
            .then((response) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.data.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.applyGetEmployeeDetailsAction();
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `${error.response.data.message}`
              );
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Erreur",
                    icon: "AlertTriangleIcon",
                    text: error.response.data.message,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
            }),
      })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },

    applyGetEmployeeNote(note) {
      this.isNotes = true;
      this.getEmployeeNoteAction({
        employeeId: this.$route.params.id,
        serviceId: note.pivot.recurring_service_id,
      })
        .then((response) => {
          this.isNotes = false;
          this.notes = response.data.notes;
          console.log(this.notes);
        })
        .catch((error) => {
          this.isNotes = false;
          console.log(error);
        });
    },

    hideModalRejectEmployee() {
      this.$bvModal.hide("modal-rejet-employee");
      this.rejectEmployeePayload = {
        reason: "",
      };
    },

    applyRejectEmployeeAction() {
      this.$refs.rejectEmployeeForm.validate().then((success) => {
        if (success) {
          this.isRejectEmployeeLoading = true;
          this.rejectEmployeeAction({
            employeeId: this.$route.params.id,
            payload: this.rejectEmployeePayload,
          })
            .then((response) => {
              this.isRejectEmployeeLoading = false;
              this.applyGetEmployeeDetailsAction();
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.hideModalRejectEmployee();
            })
            .catch((error) => {
              this.isRejectEmployeeLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Erreur",
                    icon: "AlertTriangleIcon",
                    text: error.response.data.message,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
            });
        }
      });
    },
    applySuspendedEmployeeAction() {
      this.$refs.suspendReason.validate().then((success) => {
        if (success) {
          this.isSuspendEmployeeLoading = true;
          this.suspendEmployeeAction({
            empId: this.employee.id,
            payload: { suspend_reason: this.suspend_reason },
          })
            .then((response) => {
              console.log(response.message);
              this.hideModalSuspendedEmployee();
              this.applyGetEmployeeDetailsAction();
              this.isSuspendEmployeeLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
            })
            .catch(() => {
              this.isSuspendEmployeeLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Erreur",
                    icon: "AlertTriangleIcon",
                    text: error.response.data.message,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
            });
        }
      });
    },

    applyUnSuspendedEmployeeAction() {
      this.$swal({
        title: `Annuler la suspension de ${this.employee.full_name}`,
        text: "Êtes-vous sûr de vouloir annuler la suspensionde  ce employé ?",
        icon: "info",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        preConfirm: async () =>
          await this.unsuspendEmployeeAction({ empId: this.employee.id }),
      })
        .then((response) => {
          if (response.isConfirmed) {
            this.applyGetEmployeeDetailsAction();
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Annulation de la suspension de l'employé avec succès.",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    hideModalSuspendedEmployee() {
      this.suspend_reason = "";
      this.$bvModal.hide("modal-suspend-employee");
    },
    applySendEmployeeInFormationAction() {
      const payload = {
        services: [],
      };
      this.selectedServices.forEach((element) => {
        payload.services.push(
          element
        );
      });
      console.log(payload);
       const employeeId = this.employee.id;
       console.log(employeeId)
      this.isMakingSoumissionLoading = true;
      this.sendEmployeeInFormationAction({ employeeId, payload })
        .then((response) => {
          this.isMakingSoumissionLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Succès",
                icon: "CheckIcon",
                text: response.message,
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          );
          this.$router.push("/employees?tabIndex=1");
        })
        .catch((error) => {
          console.log(error);
          this.isMakingSoumissionLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },

    applyGetEmployeeDetailsAction() {
      this.isLoadingEmployeeDetails = true;
      this.getEmployeesDetailsAction(this.$route.params.id)
        .then((response) => {
          this.isLoadingEmployeeDetails = false;
          this.employee = response.data;

          const proof_fileArray = JSON.parse(this.employee.proof_files);
          if (this.employee.caution_file != null) {
            proof_fileArray.push(this.employee.caution_file);
          }

          this.applyGetS3ProofFilesAction(proof_fileArray);
        })
        .catch((error) => {
          this.isLoadingEmployeeDetails = false;
          console.log(error);
          this.error = true;
          this.isError = Object.values(error.response.data)[0];
        });
    },

    async showWorksPicture(serviceName, pictures) {
      this.serviceName = serviceName;
      await this.applyGetS3FileTemporaryUrlAction(pictures);
    },
    applyGetS3FileTemporaryUrlAction(files) {
      if (files != null) {
        this.pictures = [];
        files.forEach((element) => {
          this.getS3FileTemporaryUrlAction(element)
            .then((response) => {
              this.pictures.push(response.fileUrl);
            })
            .catch((error) => {
              console.log(error);
              this.pictures = -1;
            });
        });
      } else {
        this.pictures = files;
      }
    },

    //  async showProofFiles(fileName, proof_files) {
    //   this.fileName = fileName
    //   await this.applyGetS3FileTemporaryUrlAction(proof_files)
    // },
    applyGetS3ProofFilesAction(files) {
      console.log("Files", files);
      console.log("Files", files.length);
      if (files != []) {
        this.proof_files = [];
        files.forEach((element) => {
          this.getS3FileTemporaryUrlAction(element)
            .then((response) => {
              if (element.endsWith("pdf")) {
                const cautionObject = { caution: response.fileUrl };
                this.proof_files.push(cautionObject);
                console.log(this.proof_files);
              } else {
                this.proof_files.push(response.fileUrl);
              }
            })

            .catch((error) => {
              console.log(error);
              this.proof_files = null;
            });
        });

        console.log("proof", this.proof_files);
      } else {
        this.proof_files = files;
      }
    },
    applyValidateEmployeeAction() {
      this.$swal({
        title: "Validation de l'employé",
        text: "Êtes-vous sûr de vouloir valider cet employé ?",
        icon: "info",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => {
          await this.validateEmployeeAction(this.employee.id);
          await this.applyGetEmployeeDetailsAction();
          this.getEmployeesAction()

            .then((response) => {})
            .catch((error) => {
              this.$swal.showValidationMessage(
                `${error.response.data.message}`
              );
            });
        },
      })
        .then((response) => {
          if (response.value) {
            this.employee = this.getEmployeeDetails;
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Employé validé avec succès",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Errabouteur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
    applyConfirmEmployeeAction() {
      this.$swal({
        title: "Confirmation de l'employé",
        text: "Êtes-vous sûr de vouloir confirmer cet employé ?",
        icon: "info",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () =>
          await this.validateEmployeeAction(this.employee.id)
            .then((response) => {})
            .catch((error) => {
              this.$swal.showValidationMessage(
                `${error.response.data.message}`
              );
            }),
      })
        .then((response) => {
          if (response.value) {
            this.employee = this.getEmployeeDetails;
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Employé confirmé avec succès",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
            this.applyGetEmployeeDetailsAction();
            this.getEmployeesAction();
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
    applyValidateEmployeeCandidatureAction() {
      this.$swal
        .fire({
          title: "Validation de la candidature de l'employé",
          text: "Êtes-vous sûr de vouloir valider cet employé ?",
          icon: "info",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: "Non",
          allowOutsideClick: false,
          confirmButtonText: "Oui",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
          // eslint-disable-next-line no-return-await
          preConfirm: async () => {
            await this.validateEmployeeCandidatureAction(this.employee.id);
            await this.applyGetEmployeeDetailsAction();
            this.getEmployeesAction()
              .then((response) => {})
              .catch((error) => {
                this.$swal.showValidationMessage(
                  `${error.response.data.message}`
                );
              });
          },
        })

        .then((response) => {
          if (response.value) {
            this.employee = this.getEmployeeDetails;
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Employé candidature validé avec succès",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
